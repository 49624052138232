import React, { useState, useContext, useEffect } from 'react';
import { Modal, Select, Divider, Input, Button, Tooltip, message } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Cliente, ClienteEtapa, Corretor } from 'smart-imob-types';
import AppContext from '../../context';

export type ClientStatus = 'ganho' | 'perdido';

interface UseClientStatusHandlerProps {
  onStatusUpdate?: (
    clientId: string,
    body: {
      status: ClientStatus;
      razao_id?: string;
      corretor_responsavel?: Corretor | null;
      corretor_responsavel_id?: string;
      etapa_funil?: ClienteEtapa;
      etapa_funil_id?: string;
    },
  ) => void;
}

interface UseClientStatusHandlerResult {
  handleStatusChange: (cliente: Cliente, status: ClientStatus) => void;
  renderStatusModal: () => JSX.Element | null;
}

export function useClientStatusHandler({ onStatusUpdate }: UseClientStatusHandlerProps): UseClientStatusHandlerResult {
  const context = useContext(AppContext);

  const [isReasonModalVisible, setIsReasonModalVisible] = useState<boolean>(false);
  const [currentClientId, setCurrentClientId] = useState<string | null>(null);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [razoes, setRazoes] = useState<any[]>([]);
  const [newReasonInput, setNewReasonInput] = useState<string>('');
  const [adding, setAdding] = useState<boolean>(false);
  const [configuracaoStatus, setConfiguracaoStatus] = useState<any>(null);

  const fetchConfiguracaoStatus = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}/empresa-configuracao-cliente-status/empresa/${context.empresa.db_id}`,
      );
      const data = await response.json();
      if (data && (data.etapas_disponiveis_ganho === 'null' || data.etapas_disponiveis_ganho === null)) {
        data.etapas_disponiveis_ganho = [];
      }
      setConfiguracaoStatus(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchRazoes = async () => {
    try {
      let params = new URLSearchParams({
        empresa_id: context.empresa.db_id,
      });
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-razoes?${params.toString()}`);
      const data = await response.json();
      setRazoes(data);
    } catch (error) {
      console.error('Erro ao buscar razões:', error);
    }
  };

  useEffect(() => {
    fetchRazoes();
    fetchConfiguracaoStatus();
  }, []);

  const addReason = async () => {
    if (!newReasonInput.trim()) {
      message.error('Por favor, insira um motivo válido.');
      return;
    }

    setAdding(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/cliente-razoes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nome: newReasonInput.trim(), empresa_id: context.empresa.db_id }),
      });

      if (!response.ok) {
        throw new Error('Erro ao adicionar o motivo');
      }

      const newReason = await response.json();
      setRazoes(prev => [...prev, newReason]);
      message.success('Motivo adicionado com sucesso!');
      setNewReasonInput('');
    } catch (error) {
      console.error('Erro ao adicionar motivo:', error);
      message.error('Erro ao adicionar o motivo. Tente novamente.');
    } finally {
      setAdding(false);
    }
  };

  function applyConfiguracaoGanho(rest: Partial<any>, configuracao: any | null, cliente: Cliente) {
    if (!configuracao) return;

    if (configuracao.mover_para_etapa_ganho_id) {
      rest.etapa_funil_id = configuracao.mover_para_etapa_ganho_id;
      rest.etapa_funil = configuracao.mover_para_etapa_ganho;
    }

    if (configuracao.remove_responsavel_ganho) {
      rest.corretor_responsavel_id = null;
      rest.corretor_responsavel = null;
    }

    if (configuracao.etapas_disponiveis_ganho?.length) {
      const etapaAutorizada = configuracao.etapas_disponiveis_ganho.includes(cliente.etapa_funil_id);

      if (!etapaAutorizada) {
        message.error('Etapa não autorizada para ganho!');
        throw new Error('Etapa não autorizada para ganho!');
      }
    }

    return rest;
  }

  function applyConfiguracaoPerdido(rest: Partial<any>, configuracao: any | null) {
    if (!configuracao) return;

    if (configuracao.mover_para_etapa_perdido_id) {
      rest.etapa_funil_id = configuracao.mover_para_etapa_perdido_id;
      rest.etapa_funil = configuracao.mover_para_etapa_perdido;
    }

    if (configuracao.remove_responsavel_perdido) {
      rest.corretor_responsavel_id = null;
      rest.corretor_responsavel = null;
    }

    return rest;
  }

  const updateClientStatus = async (clientId: string, status: ClientStatus, cliente?: Cliente, razao_id?: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/clientes/${clientId}/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status,
          razao_id,
          edited_by_id: context.user?.db_id || undefined,
        }),
      });

      if (!response.ok) {
        throw new Error('Falha ao atualizar o status do cliente');
      }
      message.success('Status atualizado com sucesso');

      if (onStatusUpdate) {
        let updatedBody: any = {
          edited_at: new Date(),
          status,
          razao_id,
          corretor_responsavel_id: undefined,
          corretor_responsavel: null,
        };

        if (status === 'ganho' && cliente) {
          updatedBody = applyConfiguracaoGanho(updatedBody, configuracaoStatus, cliente);
        }

        if (status === 'perdido') {
          updatedBody = applyConfiguracaoPerdido(updatedBody, configuracaoStatus);
        }

        console.log('updatedBody', updatedBody);
        onStatusUpdate(clientId, updatedBody);
      }
    } catch (error) {
      console.error(error);
      message.error('Erro ao atualizar status');
    }
  };

  const openReasonModal = (cliente: Cliente) => {
    setCurrentClientId(cliente.db_id);
    setSelectedReason(cliente.razao_id || null);
    setIsReasonModalVisible(true);
  };

  const closeReasonModal = () => {
    setIsReasonModalVisible(false);
    setCurrentClientId(null);
    setSelectedReason(null);
  };

  const confirmReason = () => {
    if (currentClientId && selectedReason) {
      updateClientStatus(currentClientId, 'perdido', undefined, selectedReason);
      closeReasonModal();
      message.success('Cliente marcado como perdido.');
    } else {
      message.error('Por favor, selecione um motivo.');
    }
  };

  const handleStatusChange = (cliente: Cliente, status: ClientStatus) => {
    if (status === 'perdido') {
      openReasonModal(cliente);
    } else if (status === 'ganho') {
      updateClientStatus(cliente.db_id, status, cliente, undefined);
    }
  };

  const renderStatusModal = () => (
    <Modal
      title={<span style={{ fontWeight: 'bold', fontSize: '18px' }}>Selecionar Motivo</span>}
      visible={isReasonModalVisible}
      onOk={confirmReason}
      onCancel={closeReasonModal}
      okText="Confirmar"
      cancelText="Cancelar"
      okButtonProps={{
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
        style: { backgroundColor: '#52c41a', borderColor: '#52c41a' },
      }}
      cancelButtonProps={{
        icon: <CloseCircleOutlined style={{ color: 'red' }} />,
      }}
    >
      <Select
        placeholder="Selecione um motivo"
        style={{ width: '100%' }}
        value={selectedReason || undefined}
        onChange={value => setSelectedReason(value)}
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Input
              style={{ width: '100%', margin: 8 }}
              placeholder="Digite um novo motivo"
              value={newReasonInput}
              onChange={e => setNewReasonInput(e.target.value)}
              addonAfter={
                <Tooltip title="Adicionar motivo">
                  <Button
                    icon={<PlusOutlined />}
                    onClick={addReason}
                    type="primary"
                    loading={adding}
                    disabled={adding}
                  />
                </Tooltip>
              }
            />
          </>
        )}
      >
        {razoes.map(razao => (
          <Select.Option key={razao.id} value={razao.id}>
            {razao.nome}
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );

  return { handleStatusChange, renderStatusModal };
}
